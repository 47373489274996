// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bl-header {
  color: #ffffff;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,cCGM;EDFN,eAAA;AADF","sourcesContent":["@import 'variables';\n\n.bl-header {\n  color: $white;\n  font-size: 16px;\n}\n","$brand-primary: #070D59;\n$brand-secondary: #00c1d4;\n$active-color: #3b00dd;\n$brand-black: rgb(0, 0, 0);\n$brand-light-white: rgba(255, 255, 255, 0.6);\n$brand-light-black: rgba(0, 0, 0, 0.6);\n$white: #ffffff;\n$black: #000000;\n$light-grey: rgba(0, 0, 0, 0.54);\n$lightest-grey: #e4e8ee;\n$green-6: #009900;\n$error-bg: rgb(255, 240, 239);\n$error-color: #323232;\n$error-color-2: rgb(244, 67, 54);\n$error-color-3: rgb(227, 109, 104);\n$black-1: rgb(52, 55, 51);\n$success-bg: rgb(243, 255, 237);\n$success-bg-1: #03dac4;\n$caribbean-green: #02c39a;\n$dc-data-type: #008464;\n$warning: #ff8100;\n$skipped: #ffd400;\n$violet: #6D61F6;\n// Dark Theme Colors\n$brand-dark: #051c2c;\n$brand-dark-shade: #2245c3;\n$side-nav-active: #61ccf2;\n\n// revenue leaks colors\n$revleaks-pink: #6558f5;\n$revleaks-green: #1aae9f;\n$revleaks-venetian-red: #D3455B;\n\n// revenue leaks initiative colors\n$revleaks-init-green: #00b1a2;\n$revleaks-init-red: #e93752;\n$revleaks-init-black: #233845;\n$revleaks-init-orange: #fb7d2c;\n$revleaks-init-gray: #738996;\n$revleaks-init-dark-blue: #070d59;\n$revleaks-init-light-blue: #4588D9;\n$revleaks-init-violet: #6D61F6;\n$revleaks-init-black-shade: #43505c;\n\n// alerts dashboard colors\n$alerts-dashboard-red: #ff4747;\n$alerts-dashboard-gray: #738996;\n\n//revops gradiant colors\n$revops-gradiant-light-red: #ff4747;\n$revops-gradiant-dark-red:#cb0000;\n\n//revenue leak metric info colors\n$revops-metric-info-red: #D3455B;\n$revops-metric-info-green: #55AF9F;\n$revops-metric-info-black: #4B5C6B;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
