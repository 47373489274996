import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import {
  RevenueOperationDataModel,
  RevenueOperationsDataModelColumns,
  RevOpsDashboardFilter,
  RevOpsDashboardRange
} from './revenue-operation.model';
import {
  RevenueOperationsStore,
  RevenueOperationsState,
  TimeFilter,
  ActiveDashboard,
  ActiveRevOpsCustomDashboard
} from './revenue-operations.store';
import * as moment from 'moment';
import { AppStore } from '@bli/state/app.store';

@Injectable({ providedIn: 'root' })
export class RevenueOperationsQuery extends QueryEntity<RevenueOperationsState> {
  constructor(
    private appStore: AppStore,
    protected store: RevenueOperationsStore
  ) {
    super(store);
  }

  readonly revOpsDataModel$ = this.select(state => state.dataModel);

  readonly revOpsDataModelColumns$ = this.select(
    state => state.dataModelColumns
  );

  readonly revopsGlobalFilter$ = combineLatest([
    this.select(state => state.revOpsGlobalFilter),
    this.select(state => state.activeDashboard).pipe(delay(100))
  ]).pipe(
    map(() => {
      const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
      return revOpsGlobalFilter[activeDashboard.globalFilterName];
    })
  );

  readonly activeFilterUpdate$ = this.select(
    state => state.revOpsGlobalFilter
  ).pipe(
    map(
      filter => filter[this.store.getValue().activeDashboard.globalFilterName]
    )
  );
  readonly activeFilterCaseVariants$ = this.select(state => state).pipe(
    map(filter => {
      if (
        filter.revOpsGlobalFilter[
          this.store.getValue().activeDashboard.globalFilterName
        ] &&
        filter.revOpsGlobalFilter[
          this.store.getValue().activeDashboard.globalFilterName
        ].length
      ) {
        return filter.cases_variants;
      } else {
        return null;
      }
    })
  );

  readonly disableGlobalFilter$ = this.select(
    state => state.disableGlobalFilter
  );

  readonly timeFilter$ = this.select(state => state.timeFilter);

  readonly revopsTimeFilter$ = combineLatest([
    this.select(state => state.revOpsTimeFilter),
    this.select(state => state.activeDashboard).pipe(delay(100))
  ]).pipe(
    map(() => {
      const { revOpsTimeFilter, activeDashboard } = this.store.getValue();
      return revOpsTimeFilter[activeDashboard.globalFilterName];
    })
  );

  readonly revopsDashboardRange$ = combineLatest([
    this.select(state => state.ranges),
    this.select(state => state.activeDashboard).pipe(delay(100))
  ]).pipe(
    map(() => {
      const { ranges, activeDashboard } = this.store.getValue();
      return ranges[activeDashboard.globalFilterName];
    })
  );

  readonly currentUserPermission$ = this.select(
    s => s.activeRevOpsCustomDashboard
  ).pipe(
    map(customDashboard => {
      const { groupSlug, dashboardSlug } = customDashboard;
      return this.getSelectedDashboard(groupSlug, dashboardSlug)
        .page_permission;
    })
  );

  readonly recommendation$ = this.select(state => state.recommendation);

  readonly activeDashboard$ = this.select(state => state.activeDashboard);

  set revOpsDataModel(dataModel: RevenueOperationDataModel) {
    this.store.update({
      dataModel
    });
  }

  set revOpsDataModelColumn(
    dataModelColumns: RevenueOperationsDataModelColumns[]
  ) {
    this.store.update({
      dataModelColumns
    });
  }

  set disableGlobalFilter(disableGlobalFilter: boolean) {
    this.store.update({ disableGlobalFilter });
  }

  addToGlobalFilter(itemIndex: number, filter: RevOpsDashboardFilter) {
    const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
    revOpsGlobalFilter[activeDashboard.globalFilterName].push(filter);
    this.store.update({ revOpsGlobalFilter: { ...revOpsGlobalFilter } });
    this.store.update({
      cases_variants:
        revOpsGlobalFilter[activeDashboard.globalFilterName][itemIndex]
          .cases_variants
    });
  }

  updateGlobalFilter(itemIndex: number, newFilter: RevOpsDashboardFilter) {
    const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
    revOpsGlobalFilter[activeDashboard.globalFilterName][itemIndex] = newFilter;
    this.store.update({ revOpsGlobalFilter: { ...revOpsGlobalFilter } });
    this.store.update({
      cases_variants:
        revOpsGlobalFilter[activeDashboard.globalFilterName][itemIndex]
          .cases_variants
    });
  }

  removeFromGlobalFilter(filter: RevOpsDashboardFilter) {
    const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
    revOpsGlobalFilter[activeDashboard.globalFilterName] = revOpsGlobalFilter[
      activeDashboard.globalFilterName
    ].filter(gFilter => gFilter !== filter);
    this.store.update({ revOpsGlobalFilter: { ...revOpsGlobalFilter } });
    if (revOpsGlobalFilter[activeDashboard.globalFilterName].length > 0)
      this.store.update({
        cases_variants:
          revOpsGlobalFilter[activeDashboard.globalFilterName][
            revOpsGlobalFilter[activeDashboard.globalFilterName].length - 1
          ].cases_variants
      });
    else this.store.update({ cases_variants: null });
  }

  set timeFilter(timeFilter: TimeFilter) {
    this.store.update({
      timeFilter
    });
  }

  clearGlobalFIlter() {
    const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
    revOpsGlobalFilter[activeDashboard.globalFilterName] = [];
    this.store.update({ revOpsGlobalFilter: { ...revOpsGlobalFilter } });
  }

  set revopsTimeFilter(timeFilter: TimeFilter) {
    const { revOpsTimeFilter, activeDashboard } = this.store.getValue();
    // const revopsTimeFilterModel =
    //   revOpsTimeFilter[activeDashboard.globalFilterName];
    revOpsTimeFilter[activeDashboard.globalFilterName] = timeFilter;
    this.store.update({ revOpsTimeFilter: { ...revOpsTimeFilter } });
  }

  setTimeFilterToFullYearRange() {
    this.store.resetTimeFilter();
  }

  setTimeFilterForFlowDashboard() {
    this.store.update({
      timeFilter: {
        start_date: moment()
          .set('year', 2015)
          .startOf('year')
          .format('YYYY-MM-DDTHH:mm:ss.SSS'),
        end_date: moment()
          .add(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DDTHH:mm:ss.SSS'),
        periods: 'Custom Range'
      }
    });
  }

  setTimeFilterForManagementDashboard() {
    const { fiscalYear } = this.appStore.getValue();
    if (fiscalYear.start_date && fiscalYear.end_date) {
      this.revopsTimeFilter = {
        start_date: moment(fiscalYear.start_date).format(
          'YYYY-MM-DDTHH:mm:ss.SSS'
        ),
        end_date: moment(fiscalYear.end_date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        periods: moment(fiscalYear.end_date)
          .diff(moment(fiscalYear.start_date), 'days')
          .toString()
      };
    }
    // this.store.update({
    //   timeFilter: {
    //     start_date: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    //     end_date: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    //     periods: 'This Year'
    //   }
    // });
    // this.revopsTimeFilter =   {
    //   start_date: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    //   end_date: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    //   periods: 'This Year'
    // }
  }

  reset() {
    this.store.reset();
  }

  set recommendation(recommendation) {
    this.store.update({ recommendation });
  }

  set activeDashboard(activeDashboard: ActiveDashboard) {
    this.store.update({ activeDashboard });
    this.addGlobalFilter();
    this.addDashboardRange();
    this.addRevOpsTimeFilter();
  }

  get activeDashboard() {
    return this.store.getValue().activeDashboard;
  }

  set activeRevOpsCustomDashboard(
    activeRevOpsCustomDashboard: ActiveRevOpsCustomDashboard
  ) {
    this.store.update({ activeRevOpsCustomDashboard });
  }

  addGlobalFilter() {
    const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
    if (
      !Object.prototype.hasOwnProperty.call(
        revOpsGlobalFilter,
        activeDashboard.globalFilterName
      )
    ) {
      this.store.update({
        revOpsGlobalFilter: {
          ...revOpsGlobalFilter,
          [activeDashboard.globalFilterName]: []
        }
      });
    }
  }

  addDashboardRange() {
    const { ranges, activeDashboard } = this.store.getValue();
    if (
      !Object.prototype.hasOwnProperty.call(
        ranges,
        activeDashboard.globalFilterName
      )
    ) {
      this.store.update({
        ranges: {
          ...ranges,
          [activeDashboard.globalFilterName]: {}
        }
      });
    }
  }

  addRevOpsTimeFilter() {
    const { revOpsTimeFilter, activeDashboard } = this.store.getValue();
    if (
      !Object.prototype.hasOwnProperty.call(
        revOpsTimeFilter,
        activeDashboard.globalFilterName
      )
    ) {
      this.store.update({
        revOpsTimeFilter: {
          ...revOpsTimeFilter,
          [activeDashboard.globalFilterName]: {}
        }
      });
    }
  }

  addToDashboardRange(newRange: RevOpsDashboardRange) {
    const { ranges, activeDashboard } = this.store.getValue();
    const range = ranges[activeDashboard.globalFilterName];
    ranges[activeDashboard.globalFilterName] = newRange;
    this.store.update({ ranges: { ...ranges } });
  }

  getSelectedGroup(groupSlug) {
    return this.appStore
      .getValue()
      .modules.find(m => m.module_code === 'EIFC011RO100')
      .pages.find(p => p.page_hierarchy_slug === groupSlug);
  }

  getSelectedDashboard(groupSlug, dashboardSlug) {
    return this.appStore
      .getValue()
      .modules.find(m => m.module_code === 'EIFC011RO100')
      .pages.find(p => p.page_hierarchy_slug === groupSlug)
      .sub_pages.find(s => s.page_hierarchy_slug === dashboardSlug);
  }

  get revOpsGlobalFilter() {
    const { revOpsGlobalFilter, activeDashboard } = this.store.getValue();
    return revOpsGlobalFilter[activeDashboard.globalFilterName];
  }

  get activeModuleName() {
    return this.store.getValue().activeDashboard?.moduleName;
  }

  get isConfigDashboard() {
    const { activeRevOpsCustomDashboard } = this.store.getValue();
    return (
      activeRevOpsCustomDashboard.groupSlug &&
      activeRevOpsCustomDashboard.dashboardSlug
    );
  }

  get getCurrentUserPermission() {
    const { activeRevOpsCustomDashboard } = this.store.getValue();
    const { groupSlug, dashboardSlug } = activeRevOpsCustomDashboard;
    return this.getSelectedDashboard(groupSlug, dashboardSlug).page_permission;
  }

  set cases(cases: string) {
    this.store.update({ cases });
  }
}
