import { Component, OnInit, Input } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup
} from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormField } from '../../../../../state/app.model';
import { AppService } from '../../../../../state/app.service';
import { AppQuery } from '../../../../../state/app.query';

@Component({
  selector: 'app-select-field-foster',
  templateUrl: './select-field-foster-component.html',
  styleUrls: ['./select-field-foster-component.scss']
})
export class SelectFieldFosterComponent implements OnInit {
  @Input() selectCntrl: UntypedFormControl;
  @Input() field: FormField;
  @Input() value: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() formFields: FormField[];
  filteredOptions: Observable<string[]>;

  constructor(
    private service: AppService,
    private query: AppQuery
  ) {}

  ngOnInit() {
    if (typeof this.field.enum[0].value === 'number') {
      if (
        this.selectCntrl.value &&
        this.selectCntrl.value !== '' &&
        typeof this.selectCntrl.value !== typeof this.field.enum[0].value
      )
        this.selectCntrl.setValue(Number.parseFloat(this.selectCntrl.value));
    }

    if (this.field.default && !this.selectCntrl.value) {
      this.selectCntrl.setValue(this.field.default);
    }

    if (this.formGroup.get(this.field.key)) {
      this.filteredOptions = this.formGroup
        .get(this.field.key)
        .valueChanges.pipe(
          startWith(''),
          // map(value =>  typeof value === 'string' ? value : value.name),
          map(name => (name ? this._filter(name) : this.field.enum.slice()))
        );
    }
    this.addValidation();
  }

  validateInput(event: any) {
    if (event.target.value) {
      if (
        this.field.enum.filter(s => s.value.toString() === event.target.value)
          .length == 0
      ) {
        this.selectCntrl.setValue('');
      }
    }
  }

  PanelAuto(cc: MatAutocompleteTrigger) {
    setTimeout(function a() {
      cc.closePanel();
    }, 130);
  }

  private _filter(name: string): any[] {
    const filterValue = name.toString().toLowerCase();
    return filterValue
      ? this.field.enum.filter(s => {
          if (typeof s.value === 'string') {
            return (
              s.value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
            );
          }
          if (typeof s.value === 'number') {
            return `${s.value}`.includes(filterValue);
          }
        })
      : this.field.enum;
    // this.field.enum.sort((a, b) => a.value.toString().localeCompare(b.value.toString()));
    // return this.field.enum.filter(option => option.value.toString().toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn(value?: string) {
    let displayValue = '';
    if (value && this.field.enum) {
      const model = this.field.enum.find(x => x.key === value);
      if (model != null) {
        displayValue = model.value;
      } else {
        this.formGroup.get(this.field.key).patchValue(null);
        displayValue = '';
      }
    }

    return displayValue;
  }
  checkImpacts(value) {
    if (this.field.impacts) {
      this.field.impacts.forEach(element => {
        const payload = {
          dropdown_name: element.dropdown_name,
          selected_value: value
        };
        this.service.getMultiSelectDatas(payload).subscribe(response => {
          this.formFields.forEach(field => {
            if (field.key === element.key) {
              field.enum = [];
              field.enum = response.data[0].enum;
              this.formGroup.get(field.key).patchValue(null);
              this.formGroup.get(field.key).reset();
              this.addValidation();
            }
          });
        });
      });
    }
  }

  addValidation() {
    this.formGroup.get(this.field.key).clearValidators();
    console.log(this.field);
    if (this.field.mandatory) {
      this.formGroup.get(this.field.key).setValidators([Validators.required]);
      return;
    }
  }
}
