import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStore } from './app.store';
import { RouteData, LoginMethod } from '@bli/state/app.model';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@bli-shared/utils/app-config.module';
@Injectable({ providedIn: 'root' })
export class AppService {
  private appDataSource = new BehaviorSubject({});
  private appSaveStateSource = new BehaviorSubject({});
  private paPagesListSource = new BehaviorSubject({});
  private paImportPageSource = new BehaviorSubject({});
  appDataEvent = this.appDataSource.asObservable();
  appSaveStateDataEvent = this.appSaveStateSource.asObservable();
  paPagesListSourceEvent = this.paPagesListSource.asObservable();
  paImportPageSourceEvent = this.paImportPageSource.asObservable();
  appSaveState: any = {};
  appData: any = {};
  paPagesList: any = [];
  paPageEditMode = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private appStore: AppStore,
    private http: HttpClient
  ) {
    const appData = localStorage.getItem('app-data');
    this.appData = appData ? JSON.parse(appData) : {};
  }

  saveRouteData(routeData: RouteData) {
    this.appStore.saveRouteData(routeData);
  }

  setCurrentNavigation(moduleName: string) {
    const store = this.appStore.getValue();
    if (!moduleName || !(store.navList && store.navList.length)) {
      this.appStore.update({ selectedNav: null });
      return;
    }
    if (moduleName) {
      const selectedNav = store.navList.find(nav => nav.name === moduleName);
      this.appStore.update({ selectedNav });
    }
  }

  setAppSaveState(data) {
    this.appSaveStateSource.next(data);
  }

  setBaseUrl(baseUrl: string) {
    this.appStore.update({ baseUrl });
  }

  setDomain(domainUrl: string) {
    this.appStore.update({ domainUrl });
  }

  get modulePermission() {
    return (
      this.appStore.getValue().selectedNav.permission === 'W' ||
      this.appStore.getValue().selectedNav.permission === 'RW' ||
      this.appStore.getValue().selectedNav.permission === 'WR'
    );
  }

  getAppSaveState() {
    return this.appSaveState;
  }

  setAppData(data) {
    this.appData = {
      ...this.appData,
      ...data
    };
    localStorage.setItem('app-data', JSON.stringify(this.appData));
    this.appDataSource.next(this.appData);
  }

  getAppData() {
    return this.appData;
  }

  setPAPagesEvent(data) {
    if (data.isSetPages) {
      this.paPagesList = [...data.pages];
    }
    if (data.isPageEditMode) {
      this.paPageEditMode = data.editMode;
    }
    this.paPagesListSource.next({ ...data });
  }

  setPaImportPageEvent(data) {
    this.paImportPageSource.next(data);
  }

  getPAPageEditMode() {
    return this.paPageEditMode;
  }

  getPAPagesList() {
    return this.paPagesList;
  }

  getMultiSelectDatas(request: any): Observable<any> {
    const url = `${this.rootUrl}${this.intellectEngineUrl.DROPDOWN.DROPDOWN_ATTRS}`;
    this.appStore.setLoading(true);
    return this.http.post<any>(url, request).pipe(
      tap(() => {
        this.appStore.setLoading(false);
      }),
      catchError(err => {
        this.appStore.setLoading(false);
        return throwError(err);
      })
    );
  }

  getApplicationState(payload: any): Observable<string> {
    const url = `${this.rootUrl}process_analytics/store/get/`;
    return this.http.post<string>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  saveApplicationState(payload: any): Observable<string> {
    const url = `${this.rootUrl}process_analytics/store/set/`;
    return this.http.post<string>(url, payload).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  setLoginMethod(loginMethod: LoginMethod) {
    this.appStore.update({ loginMethod });
  }

  private get rootUrl() {
    return `${this.appConfig.OPERATIONAL_INTELLECT.ROOT}/`;
  }
  private get intellectEngineUrl() {
    return this.appConfig.INTELLECT_ENGINE;
  }
}
