// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-card {
  border-radius: 0;
  padding: 10px 24px;
}
.mat-mdc-card .mat-card-actions {
  padding-left: 8px;
}
.mat-mdc-card .mat-card-content {
  margin-bottom: 0px;
}

.no-card {
  padding: 10px 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/sub-header-card/sub-header-card.component.scss"],"names":[],"mappings":"AAAE;EACA,gBAAA;EACA,kBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;;AAEA;EACE,kBAAA;AACF","sourcesContent":["  .mat-mdc-card {\n  border-radius: 0;\n  padding: 10px 24px;\n  .mat-card-actions {\n    padding-left: 8px;\n  }\n  .mat-card-content {\n    margin-bottom: 0px;\n  }\n}\n\n.no-card {\n  padding: 10px 16px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
