export const Environment = {
		  production: true,
		  appVersion: require('../../package.json').version + '-prod',
		  analysisAPIRoot: '//nextgenilb.nextgenrevops.com',
		  iEAPIRoot: '//nextgenilb.nextgenrevops.com',
		  OIAPIRoot: '//nextgenilb.nextgenrevops.com',
		  dataCollectionAPIRoot: 'nextgenilb.nextgenrevops.com:442/api/dc-v1',
		  revOperationAPIRoot: 'nextgenilb.nextgenrevops.com/api/revops',
		  utmAPIRootWithoutHyphen: 'nextgenilb.nextgenrevops.com:444/api/utm-v1/',
		  utmAPIRoot: 'nextgenilb.nextgenrevops.com:444/api/utm-v1',
		  taskMiningAPIRoot: '-be1.opxce.com/api/tmv1',
		  IIAPIRoot: '//nextgenilb.nextgenrevops.com',
		  revOpsRoot: '//nextgenilb.nextgenrevops.com/api/revops/'
		};