import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import * as moment from 'moment';
import {
  CasesAndVariants,
  RevenueOperation,
  RevenueOperationDataModel,
  RevenueOperationsDataModelColumns,
  RevOpsDashboardFilter,
  RevOpsDashboardRange
} from './revenue-operation.model';

export type DashboardType = 'funnel' | 'management' | 'custom';

export type CustomDashboardBEUrl = 'flow/' | '';

export type DashboarBEUrl =
  | 'quote/'
  | 'marketing/'
  | 'sales/'
  | 'opportunity-funnel/'
  | 'lead-funnel/'
  | 'forecast-funnel/'
  | CustomDashboardBEUrl;

export type DashboardId =
  | 'quote_management'
  | 'marketing_management'
  | 'sales_management'
  | 'opportunity_funnel'
  | 'lead_funnel'
  | 'forecast_funnel';

export type DashboardRoute =
  | 'remediation-center'
  | 'leads-central'
  | 'opportunities-central'
  | 'opportunity-funnel'
  | 'lead-funnel'
  | 'forecast-funnel'
  | 'opportunities-insights-alerts'
  | 'leads-insights-alerts'
  | '';

export type DashboardName =
  | 'REMEDIATION CENTER'
  | 'LEADS CENTRAL'
  | 'OPPORTUNITIES CENTRAL'
  | 'OPPORTUNITY FUNNEL'
  | 'LEAD FUNNEL'
  | 'FORECAST FUNNEL';

export type DashboardGlobalFilter =
  | 'quoteManagement'
  | 'marketingManagement'
  | 'salesManagement'
  | 'OpportunityFunnel'
  | 'LeadFunnel'
  | 'ForecastFunnel';

export type ScreenName =
  | 'QUOTE-MANAGEMENT'
  | 'MARKETING-MANAGEMENT'
  | 'SALES-MANAGEMENT'
  | 'OPPORTUNITY FUNNEL'
  | 'LEAD FUNNEL'
  | 'FORECAST FUNNEL';

export type ModuleName =
  | 'REVOPS-QUOTE'
  | 'REVOPS-MARKETING'
  | 'REVOPS-SALES'
  | null;

export type DashboardDisplayName = 'Opportunities' | 'Leads' | 'Forcast' | null;

export interface ActiveDashboard {
  boardType: DashboardType;
  boardBEUrl: DashboarBEUrl;
  boardId: DashboardId;
  boardRuter: DashboardRoute;
  boardName: DashboardName;
  globalFilterName: DashboardGlobalFilter;
  screenName: ScreenName;
  moduleName: ModuleName;
  displayName: DashboardDisplayName;
}

export interface TimeFilter {
  start_date: any;
  end_date: any;
  periods: any;
}
export type RevopsGlobalFilter = {
  [key in DashboardGlobalFilter]?: RevOpsDashboardFilter[];
};

export type RevopsCentralRange = {
  [key in DashboardGlobalFilter]?: RevOpsDashboardRange;
};
export type RevopsTimeFilter = {
  [key in DashboardGlobalFilter]?: TimeFilter;
};

export interface ActiveRevOpsCustomDashboard {
  groupSlug: string;
  dashboardSlug: string;
  templateType: string;
  dashboardHeightType: string;
}

export interface RevenueOperationsState extends EntityState<RevenueOperation> {
  dataModel: RevenueOperationDataModel | null;
  dataModelColumns: RevenueOperationsDataModelColumns[];
  disableGlobalFilter: boolean;
  revOpsGlobalFilter: RevopsGlobalFilter;
  cases_variants?: CasesAndVariants | null;
  timeFilter: TimeFilter;
  recommendation: any | null;
  activeDashboard: ActiveDashboard;
  ranges: RevopsCentralRange;
  revOpsTimeFilter: RevopsTimeFilter;
  activeRevOpsCustomDashboard: ActiveRevOpsCustomDashboard;
}

function timeFilterInitialState(): TimeFilter {
  return {
    start_date: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    end_date: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    periods: 'This Year'
  };
}

function objectInitialFilterData(): RevopsGlobalFilter {
  return {
    ForecastFunnel: [],
    LeadFunnel: [],
    marketingManagement: [],
    OpportunityFunnel: [],
    quoteManagement: [],
    salesManagement: []
  };
}

function objectInitialTimeFilterData(): RevopsTimeFilter {
  return {};
}

function objectInitialRangeData(): RevopsCentralRange {
  return {};
}

export function createInitialState(): RevenueOperationsState {
  return {
    dataModel: null,
    dataModelColumns: [],
    disableGlobalFilter: false,
    revOpsGlobalFilter: {},
    timeFilter: timeFilterInitialState(),
    recommendation: null,
    cases_variants: null,
    activeDashboard: {
      displayName: null,
      boardType: 'management',
      boardId: 'quote_management',
      boardName: 'REMEDIATION CENTER',
      boardRuter: 'remediation-center',
      boardBEUrl: '',
      screenName: 'QUOTE-MANAGEMENT',
      moduleName: 'REVOPS-QUOTE',
      globalFilterName: 'quoteManagement'
    },
    ranges: {},
    revOpsTimeFilter: {},
    activeRevOpsCustomDashboard: {
      dashboardSlug: '',
      groupSlug: '',
      templateType: '',
      dashboardHeightType: ''
    }
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'revenue-operations', resettable: true })
export class RevenueOperationsStore extends EntityStore<RevenueOperationsState> {
  constructor() {
    super(createInitialState());
  }

  resetTimeFilter() {
    this.update({
      timeFilter: timeFilterInitialState()
    });
  }

  resetGlobalRangeAndTimeFilter() {
    const defaultData = objectInitialFilterData();
    this.update({ revOpsGlobalFilter: defaultData });
    this.update({ ranges: objectInitialRangeData() });
    this.update({ revOpsTimeFilter: objectInitialTimeFilterData() });
  }

  resetGlobalFilters(activeDashboard) {
    if (this.getValue().revOpsGlobalFilter[activeDashboard]) {
      this.update({ revOpsGlobalFilter: { [activeDashboard]: [] } });
    }
  }
}
