import { InjectionToken } from '@angular/core';

export const APP_CONSTANTS = new InjectionToken('app.constants');

export interface IAppContants {
  SMALL_SCREEN_BREAK_POINT: string;
  LARGE_SCREEN_BREAK_POINT: string;
}

export const AppConstants: IAppContants = {
  SMALL_SCREEN_BREAK_POINT: '1023px',
  LARGE_SCREEN_BREAK_POINT: '1200px'
};

export const ProcessFilters = {
  'Case with this connection': 'directly_followed_by',
  'Case without this connection': 'not_directly_followed_by'
};

export const ActivityFilters = {
  'Case with this activity': 'cases_flow_through',
  'Case without this activity': 'cases_does_not_flow_through',
  'Case starting with this': 'cases_starts_with',
  'Case ending with this': 'cases_ends_with'
};

export const ProcessFlowFilter = {
  'Directly followed by': 'directly_followed_by',
  'Not directly followed by': 'not_directly_followed_by',
  'Followed anytime by': 'followed_anytime_by',
  'Never followed by': 'never_followed_by'
};

export const GlobalProcessFilter = {
  'directly followed by': 'directly_followed_by',
  'not directly followed by': 'not_directly_followed_by',
  'followed anytime by': 'followed_anytime_by',
  'never followed by': 'never_followed_by'
};

export const ChildNode = new Map([
  [
    'Process Overview',
    [
      {
        name: 'Overview',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/process-overview/overview'
      },
      {
        name: 'Throughput Times',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/process-overview/throughput-times'
      },
      {
        name: 'Activities',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/process-overview/activities'
      }
    ]
  ],
  [
    'Conformance Check',
    [
      {
        name: 'Overview',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/app/:pageId/conformance/overview'
      },
      // {
      //   name: 'Whitelist',
      //   activeClass: 'active-background',
      //   icon: 'label',
      //   link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/conformance-check/whitelist'
      // },
      {
        name: 'Violations',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/app/:pageId/conformance/violations'
      },
      {
        name: 'Edit Process Model',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/app/:pageId/conformance/bpmn-io'
      }
    ]
  ],
  [
    'User Management',
    [
      {
        name: 'Roles',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/user-management/roles'
      },
      {
        name: 'Users',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/user-management/users'
      },
      {
        name: 'Department',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/user-management/department'
      },
      {
        name: 'Activity Log',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/user-management/activitylogs'
      }
    ]
  ],
  [
    'Intellect Engine',
    [
      // {
      //   name: 'Overview',
      //   activeClass: 'active-background',
      //   icon: 'label',
      //   link: '/features/intellect-engine',
      // },
      // {
      //   name: 'Signal List',
      //   activeClass: 'active-background',
      //   icon: 'label',
      //   link: '/features/intellect-engine',
      // },
      // {
      //   name: 'Signal Archive',
      //   activeClass: 'active-background',
      //   icon: 'label',
      //   link: '/features/intellect-engine',
      // },
      {
        name: 'Project',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/intellect-engine/projects'
      }
      // {
      //   name: 'Adoption',
      //   activeClass: 'active-background',
      //   icon: 'label',
      //   link: '/features/intellect-engine',
      // }
    ]
  ],
  [
    'Productivity',
    [
      {
        name: 'Overview',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/overview'
      },
      {
        name: 'Top Apps & Websites',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/top-apps-and-websites'
      },
      {
        name: 'Productivity Details',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/productivity-details'
      },
      {
        name: 'Top Users and Groups',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/top-users-and-groups'
      },
      {
        name: 'Top Categories',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/top-categories'
      },
      {
        name: 'Working Hours',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/working-hours'
      },
      {
        name: 'Activity Log',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/activity-log'
      },
      {
        name: 'Screenshots',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/screenshots'
      },
      {
        name: 'Classifications',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/classifications'
      },
      {
        name: 'Groups',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/groups'
      },
      {
        name: 'Realtime Activity',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/realtime-activity'
      },
      {
        name: 'Aliases',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/aliases'
      },
      {
        name: 'Audit Logs',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/audit-logs'
      },
      {
        name: 'Schedule',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/productivity/schedule'
      }
    ]
  ],
  [
    'Operational Intellect',
    [
      {
        name: 'Executive Dashboard',
        activeClass: 'active-background',
        icon: 'business',
        link: '/features/operational-intellect/executive-board',
        navId: 'EIFC009OI101'
      } as any,
      {
        name: 'Manager Dashboard',
        activeClass: 'active-background',
        icon: 'people_fill',
        link: '/features/operational-intellect/manager-board',
        navId: 'EIFC009OI102'
      } as any,
      {
        name: 'Member Dashboard',
        activeClass: 'active-background',
        icon: 'person',
        link: '/features/operational-intellect/member-board',
        navId: 'EIFC009OI103'
      } as any
      // {
      //   name: 'Automation Board',
      //   activeClass: 'active-background',
      //   icon: 'flash_on',
      //   link: '/features/operational-intellect/automation-board',
      //   navId: 'EIFC009OI103'
      // } as any,
      // {
      //   name: 'Action History',
      //   activeClass: 'active-background',
      //   icon: 'hourglass_empty',
      //   link: '/features/operational-intellect/action-history',
      //   navId: 'EIFC009OI103'
      // } as any
    ]
  ],
  [
    'Data Source',
    [
      {
        name: 'File Upload',
        activeClass: 'active-background',
        icon: 'insert_drive_file',
        link: '/features/data-collection/:bucketPage/:bucketId/data-source/:dataSourcePage',
        navId: 'EIFC009OI101'
      } as any,
      {
        name: 'Data Connector',
        activeClass: 'active-background',
        icon: 'sensor_window',
        link: '/features/data-collection/:bucketPage/:bucketId/data-connector/:dataSourcePage',
        navId: 'EIFC009OI101'
      } as any
    ]
  ],
  [
    'RevOps Dashboards',
    [
      // {
      //   // name: 'RevOps Management',
      //   name: 'Remediation Center',
      //   activeClass: 'active-background',
      //   icon: 'receipt',
      //   link: '/features/revenue-operations/remediation-center',
      //   navId: 'EIFC009OI101',
      // } as any,
      {
        name: 'Opportunity Flow',
        activeClass: 'active-background',
        icon: 'revops:rev_opportunity_flow',
        link: '/features/revenue-operations/opportunity-funnel',
        navId: 'EIFC009OI101'
      } as any,
      {
        name: 'Lead Flow',
        activeClass: 'active-background',
        icon: 'revops:rev_lead_flow',
        link: '/features/revenue-operations/lead-funnel',
        navId: 'EIFC009OI101'
      } as any,
      {
        name: 'Forecast Flow',
        activeClass: 'active-background',
        icon: 'revops:rev_forecast_flow',
        link: '/features/revenue-operations/forecast-funnel',
        navId: 'EIFC009OI101'
      } as any,
      {
        // name: 'Sales Management',
        name: 'Opportunity Central',
        activeClass: 'active-background',
        icon: 'revops:rev_opportunity_central',
        link: '/features/revenue-operations/opportunities-central',
        navId: 'EIFC009OI101'
      } as any,
      {
        // name: 'Marketing Management',
        name: 'Lead Central',
        activeClass: 'active-background',
        icon: 'revops:rev_lead_central',
        link: '/features/revenue-operations/leads-central',
        navId: 'EIFC009OI101'
      } as any,
      {
        // name: 'Sales Management',
        name: 'Opportunity Insights',
        activeClass: 'active-background',
        icon: 'revops:rev_opportunity_insights',
        link: '/features/revenue-operations/opportunities-insights-alerts',
        navId: 'EIFC009OI101'
      } as any,
      {
        // name: 'Marketing Management',
        name: 'Lead Insights',
        activeClass: 'active-background',
        icon: 'revops:rev_lead_insights',
        link: '/features/revenue-operations/leads-insights-alerts',
        navId: 'EIFC009OI101'
      } as any
    ]
  ],
  [
    'Configurations',
    [
      {
        name: 'Automations',
        activeClass: 'active-background',
        icon: 'label',
        link: '/features/automations/list'
      },
      {
        name: 'Data Connectors',
        activeClass: 'active-background',
        icon: 'add_circle_outline',
        link: '/features/automations/data-connector'
      }
    ]
  ],
  [
    'Revenue Engine',
    [
      {
        name: 'Stages',
        activeClass: 'active-background',
        icon: 'business',
        link: '/features/revenue-leaks/stages/'
      },
      {
        name: 'Leaks',
        activeClass: 'active-background',
        icon: 'filter_alt',
        link: '/features/revenue-leaks/leaks/'
      },
      {
        name: 'Checks',
        activeClass: 'active-background',
        icon: 'check_circle_outline',
        link: '/features/revenue-leaks/checks/'
      },
      {
        name: 'Table Mappings',
        activeClass: 'active-background',
        icon: 'tune',
        link: '/features/revenue-leaks/table-mapping/'
      },
      {
        name: 'Variable Mappings',
        activeClass: 'active-background',
        icon: 'code',
        link: '/features/revenue-leaks/variable-mapping/'
      },
      {
        name: 'Schedule',
        activeClass: 'active-background',
        icon: 'query_builder',
        link: '/features/revenue-leaks/schedules/'
      },
      {
        name: 'Revenue Leaks',
        activeClass: 'active-background',
        icon: 'summarize',
        link: '/features/revenue-leaks/reports/'
      },
      {
        name: 'Initiatives',
        activeClass: 'active-background',
        icon: 'developer_board',
        link: '/features/revenue-leaks/initiatives/'
      }
    ]
  ],
  [
    'Data Collection',
    [
      {
        name: 'Buckets',
        activeClass: 'active-background',
        icon: 'storage',
        link: '/features/data-collection/1/'
      },
      {
        name: 'Connectors',
        activeClass: 'active-background',
        icon: 'add_circle_outline',
        link: '/features/data-connectors/'
      }
    ]
  ]
]);

export const SecondaryNode = [
  // {
  //   name: 'Process Overview',
  //   activeClass: 'active-background',
  //   icon: 'label',
  //   link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/process-overview',
  //   isExpandable: true
  // },
  {
    name: 'Case Explorer',
    activeClass: 'active-background',
    icon: 'label',
    link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/case-explorer',
    isExpandable: false
  },
  {
    name: 'Conformance Check',
    activeClass: 'active-background',
    icon: 'label',
    link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/conformance-check',
    isExpandable: true
  },
  {
    name: 'Process Map',
    activeClass: 'active-background',
    icon: 'label',
    link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/process-explorer',
    isExpandable: false
  }
  // {
  //   name: 'IT Service Analysis',
  //   activeClass: 'active-background',
  //   icon: 'label',
  //   link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/it-service-analysis',
  //   isExpandable: false
  // },
  // {
  //   name: 'Worsheet 1',
  //   activeClass: 'active-background',
  //   icon: 'label',
  //   link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/app/ws1',
  //   isExpandable: false
  // },
  // {
  //   name: 'Worsheet 2',
  //   activeClass: 'active-background',
  //   icon: 'label',
  //   link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/app/ws2',
  //   isExpandable: false
  // },
  // {
  //   name: 'New App',
  //   activeClass: 'active-background',
  //   icon: 'label',
  //   link: '/features/process-analytics/:cluster_id/analysis/:analysis_id/app',
  //   isExpandable: false
  // }
];

export const DataCollectionMenu = [
  {
    name: 'Data Source',
    activeClass: 'active-background',
    icon: 'storage',
    link: '/features/data-collection/:bucketPage/:bucketId/data-source/1'
    // isExpandable: true
  },
  // {
  //   name: 'Extraction',
  //   activeClass: 'active-background',
  //   icon: 'dynamic_feed',
  //   link: '/features/data-collection/data-source',
  // },
  // {
  //   name: 'Transmition',
  //   activeClass: 'active-background',
  //   icon: 'filter_list',
  //   link: '/features/data-collection/data-source',
  // },
  // {
  //   name: 'Data push Key',
  //   activeClass: 'active-background',
  //   icon: 'vpn_key',
  //   link: '/features/data-collection/:bucketId/data-push-key',
  // },
  // {
  //   name: 'Data Connector',
  //   activeClass: 'active-background',
  //   icon: 'sensor_window',
  //   link: '/features/data-collection/:bucketPage/:bucketId/data-connector/:dataSourcePage',
  //   navId: 'EIFC009OI101'
  // } as any,
  {
    name: 'Data Model',
    activeClass: 'active-background',
    icon: 'share',
    link: '/features/data-collection/:bucketPage/:bucketId/data-model/1'
  },
  {
    name: 'Data Jobs',
    activeClass: 'active-background',
    icon: 'layers',
    link: '/features/data-collection/:bucketPage/:bucketId/data-jobs/1'
  },
  {
    name: 'Schema',
    activeClass: 'active-background',
    icon: 'hourglass_empty',
    link: '/features/data-collection/:bucketPage/:bucketId/schema-explorer'
  }
];

export const ProjectMenu = [
  {
    name: 'Business Rules',
    activeClass: 'active-background',
    icon: 'rate_review',
    link: '/features/intellect-engine/projects/:project_id/business-rules'
  },
  // {
  //   name: 'Statistics',
  //   activeClass: 'active-background',
  //   icon: 'poll',
  //   link: '/features/data-collection/:bucketId/data-push-key',
  // },
  // {
  //   name: 'Monitoring',
  //   activeClass: 'active-background',
  //   icon: 'ondemand_video',
  //   link: '/features/data-collection/:bucketId/data-model',
  // },
  {
    name: 'Schedule',
    activeClass: 'active-background',
    icon: 'query_builder',
    link: '/features/intellect-engine/projects/:project_id/schedule'
  }
];

export const PrimaryNode = [
  // {
  //   name: 'Dashboard',
  //   activeClass: 'active',
  //   icon: 'dashboard',
  //   link: '/features',
  //   isExpandable: false,
  // },
  {
    name: 'Process Analytics',
    activeClass: 'active-background',
    icon: 'poll_outline',
    link: '/features/process-analytics/1',
    isExpandable: false
  },
  {
    name: 'Data Collection',
    activeClass: 'active-background',
    icon: 'storage_outline',
    link: '/features/data-collection/1',
    isExpandable: true
  },
  {
    name: 'Intelligent Insights',
    activeClass: 'active-background',
    icon: 'auto_graph',
    link: '/features/intelligent-insights',
    isExpandable: false
  },
  {
    name: 'Productivity',
    activeClass: 'active-background',
    icon: 'tune',
    link: '/features/productivity',
    isExpandable: true
  },
  {
    name: 'Intellect Engine',
    activeClass: 'active-background',
    icon: 'settings',
    link: '/features/intellect-engine',
    isExpandable: true
  },
  {
    name: 'User Management',
    activeClass: 'active-background',
    icon: 'people',
    link: '/features/user-management',
    isExpandable: true
  },
  {
    name: 'Operational Intellect',
    activeClass: 'active-background',
    icon: 'trending_up',
    link: '/features/operational-intellect',
    isExpandable: true
  },
  {
    name: 'Configurations',
    activeClass: 'active-background',
    icon: 'trending_up',
    link: '/features/automations',
    isExpandable: true
  },
  {
    name: 'Revenue Engine',
    activeClass: 'active-background',
    icon: 'trending_up',
    link: '/features/revenue-leaks',
    isExpandable: true
  },
  {
    name: 'Revenue Operations',
    activeClass: 'active-background',
    icon: 'trending_up',
    // link: '/features/revenue-operations/leads-central',
    link: '',
    isExpandable: true
  },
  {
    name: 'RevOps Dashboards',
    activeClass: 'active-background',
    icon: 'trending_up',
    link: '/features/revenue-operations/leads-central',
    isExpandable: true
  }
];

export const ListComponents = [
  {
    title: 'Process Analysis Components',
    items: [
      {
        name: 'Process Explorer',
        icon: '',
        component_name: 'app-path-activity',
        component_type: 'PROCESS-EXPLORER',
        meta: {
          config_type: 'NONE'
        }
      },
      {
        name: 'Variant Explorer',
        icon: '',
        component_name: 'app-process-explorer-overview',
        component_type: 'VARIANT-EXPLORER',
        meta: {
          config_type: 'NONE'
        }
      },
      {
        name: 'Case Explorer',
        icon: '',
        component_name: 'app-case-explorer-dashboard',
        component_type: 'VARIANT-EXPLORER',
        meta: {
          config_type: 'NONE'
        }
      },
      {
        name: 'Funnel Analysis',
        icon: '',
        component_name: 'app-pa-funnel-analysis',
        component_type: 'FUNNEL-ANALYSIS',
        meta: {
          config_type: 'NONE'
        }
      }
    ]
  },
  {
    title: 'Charts And Tables',
    items: [
      {
        name: 'Bar Chart',
        icon: '',
        component_name: 'app-charts',
        component_type: 'BAR-CHART',
        meta: {
          config_type: 'DEFAULT'
        }
      },
      {
        name: 'Doughnut Chart',
        icon: '',
        component_name: 'app-doughnut-chart',
        component_type: 'DONUT-CHART',
        meta: {
          config_type: 'PIE_CONFIG'
        }
      },
      {
        name: 'Line Chart',
        icon: '',
        component_name: 'app-line-chart',
        component_type: 'LINE-CHART',
        meta: {
          config_type: 'LINE_CONFIG'
        }
      },
      {
        name: 'OLAP Tables',
        icon: '',
        component_name: 'app-olap',
        component_type: 'OLAP-TABLE',
        meta: {
          config_type: 'OLAP_CONFIG'
        }
      },
      {
        name: 'Pie Chart',
        icon: '',
        component_name: 'app-pie-chart',
        component_type: 'PIE-CHART',
        type: 'pie',
        meta: {
          config_type: 'PIE_CONFIG'
        }
      },
      // {
      //   name: 'Line Chart',
      //   icon: ''
      // },
      {
        name: 'Area Chart',
        icon: '',
        component_name: 'app-area-chart',
        component_type: 'LINE-CHART',
        meta: {
          config_type: 'LINE_CONFIG'
        }
      },
      // {
      //   name: 'Marker Chart',
      //   icon: ''
      // },
      {
        name: 'Bubble Plot',
        icon: 'bubble',
        component_name: 'app-bubble-chart',
        component_type: 'BUBBLE-CHART',
        meta: {
          config_type: 'BUBBLE_CONFIG'
        }
      },
      {
        name: 'Histogram Chart',
        icon: '',
        component_name: 'app-histogram-chart',
        component_type: 'HISTOGRAM-CHART',
        meta: {
          config_type: 'HISTOGRAM_CONFIG'
        }
      },
      {
        name: 'Scatter Plot',
        icon: '',
        component_name: 'app-scatter-plot',
        component_type: 'SCATTER-CHART',
        meta: {
          config_type: 'SCATTER_CONFIG'
        }
      },
      // {
      //   name: 'Pivot Table',
      //   icon: ''
      // },
      // {
      //   name: 'Box Plot',
      //   icon: '',
      //   component_name: '',
      //   component_type: 'BOX-PLOT',
      //   meta: {
      //     config_type: 'BOX_PLOT_CONFIG'
      //   }
      // },
      // {
      //   name: 'World Map',
      //   icon: ''
      // },
      {
        name: 'Waterfall Chart',
        icon: '',
        component_name: 'app-waterfall-chart',
        component_type: 'WATERFALL-CHART',
        meta: {
          config_type: 'WATERFALLCHART_CONFIG'
        }
      },
      {
        name: 'Funnel Chart',
        icon: '',
        component_name: 'app-funnel-chart',
        component_type: 'FUNNEL-CHART',
        meta: {
          config_type: 'FUNNELCHART_CONFIG'
        }
      }
    ]
  },
  {
    title: 'Single KPI Components',
    items: [
      {
        name: 'Gauge',
        icon: '',
        component_name: 'app-gauge-kpi',
        component_type: 'KPI',
        meta: {
          component_gaugetype: 1,
          config_type: 'KPI'
        }
      },
      {
        name: 'Fill',
        icon: '',
        component_name: 'app-fill-kpi',
        component_type: 'KPI',
        meta: {
          component_gaugetype: 3,
          config_type: 'KPI'
        }
      },
      {
        name: 'Number',
        icon: '',
        component_name: 'app-singlekpi-number',
        component_type: 'KPI',
        meta: {
          config_type: 'KPI'
        }
      },
      {
        name: 'Radial',
        icon: '',
        component_name: 'app-radial-kpi',
        component_type: 'KPI',
        meta: {
          component_gaugetype: 2,
          config_type: 'KPI'
        }
      }
    ]
  },
  {
    title: 'Selection Components',
    items: [
      {
        name: 'Dropdown',
        icon: '',
        component_name: 'app-dropdown',
        component_type: 'DROPDOWN',
        meta: {
          config_type: 'DROPDOWN'
        }
      },
      {
        name: 'Date Picker',
        icon: '',
        component_name: 'app-datepicker',
        component_type: 'DATE-PICKER',
        meta: {
          config_type: 'DATEPICKER'
        }
      }
      // {
      //   name: 'Cropping',
      //   icon: ''
      // },
      // {
      //   name: 'Search',
      //   icon: ''
      // }
    ]
  }
  // {
  //   title: 'Design Components',
  //   items: [
  //     {
  //       name: 'Variable Input',
  //       icon: ''
  //     },
  //     {
  //       name: 'Button',
  //       icon: ''
  //     },
  //     {
  //       name: 'Button Dropdown',
  //       icon: ''
  //     },
  //     {
  //       name: 'Text Component',
  //       icon: ''
  //     },
  //     {
  //       name: 'Image',
  //       icon: ''
  //     },
  //     {
  //       name: 'Line',
  //       icon: ''
  //     },
  //     {
  //       name: 'Logo',
  //       icon: ''
  //     }
  //   ]
  // },
  // {
  //   title: 'Diagrams',
  //   items: [
  //     {
  //       name: 'Sankey',
  //       icon: '',
  //       component_name: 'app-sankey',
  //       // component_name: '',
  //       component_type: 'SANKEY',
  //       meta: {
  //         config_type: 'SANKEY'
  //       }
  //     },
  //   ]
  // }
];
