import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTPInterceptor } from './shared/utils/http-interceptor';
import { HttpCancelService } from './shared/utils/http-cancel.service';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
// import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
// import { Environment } from '../environments/environment';
import { ErrorInterceptor } from '@bli-shared/utils/error-interceptor';
import { GlobalErrorHandler } from '@bli-shared/utils/global-error-handler';
import { AppVersionComponent } from './app-version/app-version.component';
import { SlackAckComponent } from './slack/slack-ack/slack-ack.component';
import { SlackActionCallbackComponent } from './slack/slack-action-callback/slack-action-callback.component';
import { FivetranAuthAckComponent } from './fivetran-auth-ack/fivetran-auth-ack.component';
import { SalesforceAuthCallbackComponent } from './salesforce-auth-callback/salesforce-auth-callback.component';
// import { RouterModule } from '@angular/router';

import 'ag-grid-enterprise';

import { LicenseManager } from 'ag-grid-enterprise';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppVersionComponent,
    SlackAckComponent,
    SlackActionCallbackComponent,
    FivetranAuthAckComponent,
    SalesforceAuthCallbackComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
    // Environment.production ? [] : AkitaNgDevtools.forRoot({ shallow: false, sortAlphabetically: true })
  ],
  providers: [
    HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    LicenseManager.setLicenseKey(
      `[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052646}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{29 February 2024}____[v3]_[0102]_MTcwOTE2NDgwMDAwMA==b1896dfdfbb4dc28dfd4e4366ce39bbd`
    );
  }
}
